import { FC } from 'react';

import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterContent, { FilterContent } from 'src/components/NovaFilters/components/NovaFilterContent';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import NovaXSFilter from 'src/components/NovaFilters/components/NovaXSFilter';
import { useSelector } from 'src/hooks/useSelector';
import NovaFilters from 'src/models/novaFilters';

export interface NovaFilterProps extends FilterContent {
    title: string;
}

const NovaFilter: FC<NovaFilterProps> = ({ title, name, ...props }) => {
    const clusters = useSelector((state) => state.searchClusters);

    const cluster = clusters?.[name as keyof NovaFilters];
    if (!cluster || Object.values(cluster.groups).length === 0) {
        return null;
    }

    return (
        <NovaControl mobileView={<NovaXSFilter {...props} name={name} title={title} />}>
            <NovaFilterWrapper title={title}>
                <NovaFilterContent {...props} name={name} />
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default NovaFilter;
