import { useCallback, useMemo, useRef } from 'react';

import { Cell, CellText, Radio, Select, SelectOption, SelectOptionProps } from '@hh.ru/magritte-ui';
import { LanguageLevel as LanguageLevelType } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { Language, LanguageLevel } from 'src/models/resumeLanguage';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

const TrlKeys = {
    placeholder: 'search.filter.language.level.placeholder',
    [LanguageLevelType.A1]: 'clusters.language.degree.a1',
    [LanguageLevelType.A2]: 'clusters.language.degree.a2',
    [LanguageLevelType.B1]: 'clusters.language.degree.b1',
    [LanguageLevelType.B2]: 'clusters.language.degree.b2',
    [LanguageLevelType.C1]: 'clusters.language.degree.c1',
    [LanguageLevelType.C2]: 'clusters.language.degree.c2',
    [LanguageLevelType.L1]: 'clusters.language.degree.l1',
};

type Props = {
    grade: string;
    onChange: (value: string) => void;
    levels: LanguageLevel[];
    dataQa: string;
};

const LanguageLevelSelect: TranslatedComponent<Props> = ({ trls, grade, onChange, levels, dataQa }) => {
    const options: Array<SelectOption<Language>> = useMemo(
        () => levels.map(({ id, value }) => ({ id, value, label: trls[TrlKeys[value]] })),
        [levels, trls]
    );

    const renderOption = useCallback(
        ({ label, value }: SelectOptionProps<Language>) => (
            <Cell left={<Radio checked={grade === value} readOnly />} onChange={() => onChange(value)}>
                <CellText data-qa={`${dataQa}-${value}`}>{label}</CellText>
            </Cell>
        ),
        [dataQa, grade, onChange]
    );

    const selectHostRef = useRef<HTMLDivElement>(null);

    return (
        <div style={{ position: 'relative' }} ref={selectHostRef} data-qa={dataQa}>
            <Select
                name={CriteriaKey.Language}
                placeholder={trls[TrlKeys.placeholder]}
                value={grade}
                options={options}
                bottomSheetHeight="content"
                renderItem={renderOption}
                renderItemForDesktop={renderOption}
                onChange={onChange}
            />
        </div>
    );
};

export default translation(LanguageLevelSelect);
