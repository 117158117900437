import { useMemo, Fragment } from 'react';

import { Checkbox as MagritteCheckbox, Link as MagritteLink } from '@hh.ru/magritte-ui';
import Checkbox from 'bloko/blocks/checkbox';
import CompositeSelection from 'bloko/blocks/compositeSelection';
import Link, { LinkAppearance } from 'bloko/blocks/link';
import BlokoTranslateGuard from 'bloko/blocks/translateGuard';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';

import FilterCount from 'src/components/NovaFilters/components/FilterCount';
import FilterList from 'src/components/NovaFilters/components/FilterList';
import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';
import LinkMore from 'src/components/NovaFilters/components/LinkMore';
import ListItem from 'src/components/NovaFilters/components/ListItem';
import MagritteFilterList from 'src/components/NovaFilters/components/Magritte/FilterList';
import MagritteLinkMore from 'src/components/NovaFilters/components/Magritte/LinkMore';
import MagritteNovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useChangeParentTreeValue from 'src/components/NovaFilters/hooks/useChangeParentTreeValue';
import useNovaFiltersGroups from 'src/components/NovaFilters/hooks/useNovaFiltersGroups';
import translation from 'src/components/translation';
import { NovaFilterGroupMap } from 'src/models/novaFilters';

export const isCheckedOrIndeterminate = (
    id: string,
    checkedValues: string[]
): { checked: boolean; indeterminate: boolean } => {
    const checked = checkedValues.includes(id);
    let indeterminate = false;
    if (!checked) {
        indeterminate = checkedValues.some((item) => item === id);
    }
    return {
        checked,
        indeterminate,
    };
};

interface Filter {
    groups: NovaFilterGroupMap<string>;
}

export const getChildrenWithCounts = (parent?: Filter, children?: Filter): Record<string, number> => {
    const result: Record<string, number> = {};
    if (!parent) {
        return result;
    }
    Object.values(parent.groups).forEach(({ id, count }) => {
        result[id] = count;
    });
    if (children) {
        Object.values(children.groups).forEach(({ id, count }) => {
            result[id] = count;
        });
    }
    return result;
};

const EMPTY_GROUP = {};
const TrlKeys = {
    chooseMore: 'clusters.chooseMore',
    placeholder: 'treeselector.quicksearch',
    notFound: 'treeselector.notFound',
    save: 'treeselector.save',
    cancel: 'treeselector.cancel',
};

interface NovaFilterParentTreeProps {
    title?: string;
    setCheckedValuesWithOnChange: (newValues: string[]) => void;
    treeCollection: TreeCollection;
    selectedValues: string[];
    parentFilter?: Filter;
    childrenFilter?: Filter;
    order?: string[];
    isMagritte?: boolean;
}

const NovaFilterParentTree: TranslatedComponent<NovaFilterParentTreeProps> = ({
    title,
    trls,
    setCheckedValuesWithOnChange,
    treeCollection,
    selectedValues,
    parentFilter,
    childrenFilter,
    order = [],
    isMagritte,
}) => {
    const groups = parentFilter?.groups || EMPTY_GROUP;
    const { items, breakpoint } = useNovaFiltersGroups(groups || EMPTY_GROUP, selectedValues.length, order);
    const counts = useMemo(() => getChildrenWithCounts(parentFilter, childrenFilter), [parentFilter, childrenFilter]);
    const onChangeParent = useChangeParentTreeValue(selectedValues, setCheckedValuesWithOnChange);

    if (isMagritte) {
        return (
            <MagritteNovaFilterWrapper title={title}>
                <MagritteFilterList>
                    {items.map(({ title, id }, index) => {
                        if (index > breakpoint - 1) {
                            return null;
                        }
                        const { checked, indeterminate } = isCheckedOrIndeterminate(id, selectedValues);
                        const group = parentFilter?.groups[id];
                        return (
                            <MagritteNovaFilterItemWrapper
                                key={id}
                                left={
                                    <MagritteCheckbox
                                        value={id}
                                        onChange={({ target }) => {
                                            onChangeParent(target.value);
                                        }}
                                        checked={checked}
                                        indeterminate={indeterminate}
                                        dataQaCheckbox="serp__novafilter-item-text"
                                    />
                                }
                                title={title}
                                count={group?.count}
                                disabled={!group}
                            />
                        );
                    })}
                </MagritteFilterList>
                <MagritteLinkMore>
                    {/* TODO: перевести на магритт в https://jira.hh.ru/browse/PORTFOLIO-30300 */}
                    <CompositeSelection
                        collection={treeCollection}
                        value={selectedValues}
                        onChange={setCheckedValuesWithOnChange}
                        LabelComponent={({ id, text }) => (
                            <BlokoTranslateGuard useSpan>
                                {text}
                                <FilterCount count={counts[id]} />
                            </BlokoTranslateGuard>
                        )}
                        title={title}
                        trl={{
                            submit: trls[TrlKeys.save],
                            cancel: trls[TrlKeys.cancel],
                            searchPlaceholder: trls[TrlKeys.placeholder],
                            notFound: trls[TrlKeys.notFound],
                        }}
                    >
                        {({ showTreeSelectorPopup }) => (
                            <MagritteLink Element="button" onClick={showTreeSelectorPopup}>
                                {trls[TrlKeys.chooseMore]}
                            </MagritteLink>
                        )}
                    </CompositeSelection>
                </MagritteLinkMore>
            </MagritteNovaFilterWrapper>
        );
    }

    return (
        <Fragment>
            <NovaFilterWrapper title={title}>
                <FilterList>
                    {items.map(({ title, id }, index) => {
                        if (index > breakpoint - 1) {
                            return null;
                        }
                        const { checked, indeterminate } = isCheckedOrIndeterminate(id, selectedValues);
                        const group = parentFilter?.groups[id];
                        return (
                            <ListItem key={id}>
                                <Checkbox
                                    value={id}
                                    onChange={({ target }) => {
                                        onChangeParent(target.value);
                                    }}
                                    checked={checked}
                                    indeterminate={indeterminate}
                                    labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                                    disabled={!group}
                                >
                                    <FilterTitle title={title} />
                                    {group && <FilterCount count={group.count} />}
                                </Checkbox>
                            </ListItem>
                        );
                    })}
                </FilterList>
                <LinkMore>
                    <CompositeSelection
                        collection={treeCollection}
                        value={selectedValues}
                        onChange={setCheckedValuesWithOnChange}
                        LabelComponent={({ id, text }) => (
                            <BlokoTranslateGuard useSpan>
                                {text}
                                <FilterCount count={counts[id]} />
                            </BlokoTranslateGuard>
                        )}
                        title={title}
                        trl={{
                            submit: trls[TrlKeys.save],
                            cancel: trls[TrlKeys.cancel],
                            searchPlaceholder: trls[TrlKeys.placeholder],
                            notFound: trls[TrlKeys.notFound],
                        }}
                    >
                        {({ showTreeSelectorPopup }) => (
                            <Link appearance={LinkAppearance.Pseudo} onClick={showTreeSelectorPopup}>
                                {trls[TrlKeys.chooseMore]}
                            </Link>
                        )}
                    </CompositeSelection>
                </LinkMore>
            </NovaFilterWrapper>
        </Fragment>
    );
};

export default translation(NovaFilterParentTree);
