import InputText from 'bloko/blocks/inputText';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import MagritteNovaChipsItem from 'src/components/NovaFilters/components/Magritte/NovaChipsItem';
import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterContent from 'src/components/NovaFilters/components/Magritte/NovaFilterContent';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterContent from 'src/components/NovaFilters/components/NovaFilterContent';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey, NovaFilterCompany } from 'src/models/novaFilters';

const getCompanyName = (cluster: NovaFilterCompany) => {
    const { groups, selectedValues } = cluster;
    return selectedValues.map((id) => groups[id].name).join(', ');
};

const TrlKeys = {
    title: 'searchvacancy.clusters.company',
};

interface Props {
    name: typeof NovaFilterKey.Company;
    isMagritte?: boolean;
}

const Company: TranslatedComponent<Props> = ({ name, isMagritte, trls }) => {
    const sendCountsRequest = useDebouncedCountsRequest();
    const filterUpdate = useNovaFilterUpdate();
    const cluster = useSelector(({ searchClusters }) => searchClusters?.[name]);

    if (!cluster || Object.values(cluster.groups).length === 0 || !cluster.selectedValues.length) {
        return null;
    }

    if (isMagritte) {
        return (
            <MagritteNovaControl
                mobileView={
                    <MagritteNovaChipsItem
                        title={trls[TrlKeys.title]}
                        value={getCompanyName(cluster)}
                        onDelete={() => {
                            filterUpdate([], NovaFilterKey.Company);
                            sendCountsRequest();
                        }}
                    />
                }
            >
                <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                    <MagritteNovaFilterContent name={name} />
                </MagritteNovaFilterWrapper>
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl
            mobileView={
                <>
                    <Text Element="span" size={TextSize.Large} strong>
                        {trls[TrlKeys.title]}
                    </Text>
                    <VSpacing base={3} />
                    <InputText
                        showClearButton
                        value={getCompanyName(cluster)}
                        placeholder={trls[TrlKeys.title]}
                        onChange={() => {
                            filterUpdate([], NovaFilterKey.Company);
                            sendCountsRequest();
                        }}
                        disabled
                    />
                    <VSpacing base={6} />
                </>
            }
        >
            <NovaFilterWrapper title={trls[TrlKeys.title]}>
                <NovaFilterContent name={name} />
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(Company);
