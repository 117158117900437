import { useMemo } from 'react';

import InputText, { InputChangeHandler, InputType } from 'bloko/blocks/inputText';
import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Currency from 'src/components/Currency';
import NovaChipsSelect, { ChipsSelectType } from 'src/components/NovaFilters/components/NovaChipsSelect';
import useNovaFilterUpdateByCurrency from 'src/components/NovaFilters/hooks/useNovaFilterUpdateByCurrency';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { CurrencyType } from 'src/models/currencies.types';

import styles from './styles.less';

interface CompensationMobileProps {
    amount: string;
    onChange: InputChangeHandler;
    title: string;
}

const TrlKeys = {
    title: 'novaFilters.compensation',
};

const CompensationMobile: TranslatedComponent<CompensationMobileProps> = ({ trls, onChange, amount, title }) => {
    const sendCountsRequest = useDebouncedCountsRequest();
    const setCurrency = useNovaFilterUpdateByCurrency();
    const currency = useSelector((state) => state.criteriaCurrencyCode);
    const currencies = useSelector((state) => state.currencies.list);
    const options = useMemo(
        () =>
            currencies.map(({ code }) => ({
                id: code,
                title: (
                    <span className={styles.currency}>
                        <Currency value={code} />
                    </span>
                ),
            })),
        [currencies]
    );
    return (
        <>
            <Text Element="span" size={TextSize.Large} strong>
                {title}
            </Text>
            <VSpacing base={3} />
            <InputText
                showClearButton
                type={InputType.Number}
                value={amount}
                data-qa="novafilters-mobile-custom-compensation"
                placeholder={trls[TrlKeys.title]}
                onChange={onChange}
            />
            <VSpacing base={3} />
            <NovaChipsSelect
                name={'search_currency'}
                selected={[currency]}
                data-qa="serp-settings__search-currency"
                options={options}
                selectType={ChipsSelectType.Single}
                onChange={(values) => {
                    setCurrency(values[0] as CurrencyType);
                    sendCountsRequest();
                }}
            />
            <VSpacing base={4} />
        </>
    );
};

export default translation(CompensationMobile);
