import { useCallback, useRef, useState } from 'react';

import { Checkbox as MagritteCheckbox } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import FilterList from 'src/components/NovaFilters/components/FilterList';
import LinkMore from 'src/components/NovaFilters/components/LinkMore';
import MagritteFilterList from 'src/components/NovaFilters/components/Magritte/FilterList';
import MagritteLinkMore from 'src/components/NovaFilters/components/Magritte/LinkMore';
import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import useNovaFiltersGroups from 'src/components/NovaFilters/hooks/useNovaFiltersGroups';
import { isMetroCheckedOrIndeterminate } from 'src/components/NovaFilters/novaFilterUtils';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey, MetroType, NovaFilterMetroGroup } from 'src/models/novaFilters';
import { EMPTY_CLUSTER } from 'src/models/searchClusters';

import MetroCheckbox from 'src/components/NovaFilters/vacancies/Metro/MetroCheckbox';
import MetroMobile from 'src/components/NovaFilters/vacancies/Metro/MetroMobile';
import { sortMetroByTitleDesc } from 'src/components/NovaFilters/vacancies/Metro/utils/sort';

const TrlKeys = {
    title: 'searchvacancy.clusters.metro',
    inputPlaceholder: 'search.clusters.input.placeholder',
};

const Metro: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte, trls }) => {
    const filterUpdate = useNovaFilterUpdate();

    const { groups, selectedValues } =
        useSelector((state) => state.searchClusters[NovaFilterKey.Metro]) || EMPTY_CLUSTER;
    const orders = useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.Metro]);

    const [isExpanded, setIsExpanded] = useState(false);
    const { items, breakpoint } = useNovaFiltersGroups(
        groups,
        selectedValues.length,
        orders,
        isExpanded,
        sortMetroByTitleDesc
    );
    const metroList = items as NovaFilterMetroGroup[];

    const handleMetro = useCallback(
        (metro: string, group: NovaFilterMetroGroup) => {
            let values = selectedValues.slice();
            const index = values.indexOf(metro);
            if (index !== -1) {
                values.splice(index, 1);
            } else {
                // if use line filter
                // delete all station for selected line
                if (group.type === MetroType.Line) {
                    values = values.filter((item) => item.split('.')[0] !== group.lineId);
                }
                // if use station filter - delete line
                if (group.type === MetroType.Station) {
                    const [line] = metro.split('.');
                    values = values.filter((item) => item !== line);
                }
                values.push(metro);
            }
            filterUpdate(values, NovaFilterKey.Metro);
        },
        [filterUpdate, selectedValues]
    );

    const scrollableContainerRef = useRef<HTMLUListElement>(null);

    if (Object.values(groups).length === 0) {
        return null;
    }

    if (isMagritte) {
        return (
            <MagritteNovaControl mobileView={<MetroMobile title={trls[TrlKeys.title]} isMagritte />}>
                <MagritteNovaFilterWrapper title={trls[TrlKeys.title]}>
                    <MagritteFilterList ref={scrollableContainerRef} expanded={isExpanded} len={metroList.length}>
                        {metroList.map((metro, index) => {
                            const { id, type, title, color, count, disabled } = metro;
                            if (index >= breakpoint && !isExpanded) {
                                return null;
                            }
                            const { checked, indeterminate } = isMetroCheckedOrIndeterminate(
                                id,
                                selectedValues,
                                color,
                                type
                            );
                            return (
                                <MagritteNovaFilterItemWrapper
                                    key={id}
                                    left={
                                        <MagritteCheckbox
                                            value={id}
                                            name={NovaFilterKey.Metro}
                                            onChange={({ target }) => {
                                                handleMetro(target.value, metro);
                                            }}
                                            checked={checked}
                                            indeterminate={indeterminate}
                                            dataQaCheckbox={`serp__novafilter-metro-${id}`}
                                        />
                                    }
                                    title={title}
                                    count={count}
                                    disabled={disabled}
                                />
                            );
                        })}
                    </MagritteFilterList>
                    {metroList.length > breakpoint && (
                        <MagritteLinkMore
                            length={metroList.length - breakpoint}
                            expanded={isExpanded}
                            onClick={() => {
                                setIsExpanded(!isExpanded);
                            }}
                        />
                    )}
                </MagritteNovaFilterWrapper>
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl mobileView={<MetroMobile title={trls[TrlKeys.title]} />}>
            <NovaFilterWrapper title={trls[TrlKeys.title]}>
                <FilterList ref={scrollableContainerRef} expanded={isExpanded} len={metroList.length}>
                    {metroList.map(({ id, color, type, ...metro }, index) => {
                        if (index >= breakpoint && !isExpanded) {
                            return null;
                        }
                        const { checked, indeterminate } = isMetroCheckedOrIndeterminate(
                            id,
                            selectedValues,
                            color,
                            type
                        );
                        return (
                            <MetroCheckbox
                                key={id}
                                id={id}
                                name={NovaFilterKey.Metro}
                                color={color}
                                type={type}
                                checked={checked}
                                indeterminate={indeterminate}
                                onChange={handleMetro}
                                {...metro}
                            />
                        );
                    })}
                </FilterList>
                {metroList.length > breakpoint && (
                    <LinkMore
                        length={metroList.length - breakpoint}
                        expanded={isExpanded}
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                    />
                )}
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(Metro);
