import { useMemo } from 'react';

import { Checkbox as MagritteCheckbox, Loader, Link as MagritteLink } from '@hh.ru/magritte-ui';
import Checkbox from 'bloko/blocks/checkbox';
import CompositeSelection from 'bloko/blocks/compositeSelection';
import BlokoLink, { LinkAppearance } from 'bloko/blocks/link';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { filterUniqueLeavesOnly } from 'bloko/common/tree/treeCollectionHelper';
import { AdditionalDefault } from 'bloko/common/tree/types';

import FilterCount from 'src/components/NovaFilters/components/FilterCount';
import FilterList from 'src/components/NovaFilters/components/FilterList';
import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';
import LinkMore from 'src/components/NovaFilters/components/LinkMore';
import ListItem from 'src/components/NovaFilters/components/ListItem';
import MagritteFilterList from 'src/components/NovaFilters/components/Magritte/FilterList';
import MagritteLinkMore from 'src/components/NovaFilters/components/Magritte/LinkMore';
import MagritteNovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFiltersGroups from 'src/components/NovaFilters/hooks/useNovaFiltersGroups';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import useIsEmployerVacancySearch from 'src/models/employerVacancySearch/useIsEmployerVacancySearch';
import { NovaFilterGroup, NovaFilterKey, OTHER_ROLES_ID } from 'src/models/novaFilters';

const sortOther = (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>): number => {
    if (a.id === OTHER_ROLES_ID) {
        return 1;
    }
    if (b.id === OTHER_ROLES_ID) {
        return -1;
    }
    return 0;
};

interface ProfessionalRolesPros {
    groups: Record<string, NovaFilterGroup<string>>;
    selectedValues: string[];
    title: string;
    onChange: (values: string[]) => void;
    collection: TreeCollection<AdditionalDefault>;
    isMagritte?: boolean;
}

const TrlKeys = {
    chooseMore: 'clusters.chooseMore',
    placeholder: 'treeselector.quicksearch',
    notFound: 'treeselector.notFound',
    save: 'treeselector.save',
    cancel: 'treeselector.cancel',
    notice: 'profession_role.search.notice',
    noticeLink: 'profession_role.search.notice.link',
    closeNotice: 'profession_role.search.notice.close',
};

const ProfessionalRolesDesktop: TranslatedComponent<ProfessionalRolesPros> = ({
    groups,
    selectedValues,
    title,
    trls,
    collection,
    onChange,
    isMagritte,
}) => {
    const orders = useSelector((state) => state.searchClustersOrder?.[NovaFilterKey.ProfessionalRole]) || [];
    const { items, breakpoint } = useNovaFiltersGroups(groups, selectedValues.length, orders);
    const isEmployerVacancySearch = useIsEmployerVacancySearch();
    const filteredItems = useMemo(() => {
        const filteredItems = items.slice(0, breakpoint);
        if (!selectedValues.includes(OTHER_ROLES_ID)) {
            filteredItems.sort(sortOther);
        }
        return filteredItems;
    }, [breakpoint, selectedValues, items]);

    const onChangeSelection = (items: string[]) => {
        if (!collection) {
            return;
        }
        const newRoles: string[] = [];
        const topLvl = collection.getTopLevel().map(({ id }) => id);
        items.forEach((item) => {
            if (topLvl.includes(item)) {
                // for top categories
                // using children
                newRoles.push(...collection.getChildrenIds(item));
            } else {
                // or push new role
                newRoles.push(item);
            }
        });
        // delete duplicate
        const uniqueRoles = [...new Set(newRoles)];
        onChange(uniqueRoles);
    };

    const onChangeFilter = (item: string) => {
        const newRoles = [...selectedValues];
        const index = newRoles.indexOf(item);
        if (index !== -1) {
            newRoles.splice(index, 1);
        } else {
            newRoles.push(item);
        }
        onChange(newRoles);
    };

    if (isMagritte) {
        return (
            <MagritteNovaFilterWrapper title={title}>
                <MagritteFilterList>
                    {filteredItems.map(({ title, id }) => {
                        const group = groups[id];
                        return (
                            <MagritteNovaFilterItemWrapper
                                key={id}
                                left={
                                    <MagritteCheckbox
                                        value={id}
                                        onChange={({ target }) => {
                                            onChangeFilter(target.value);
                                        }}
                                        checked={selectedValues.includes(id)}
                                        disabled={!group}
                                    />
                                }
                                title={title}
                                count={group.count}
                                disabled={!group}
                            />
                        );
                    })}
                </MagritteFilterList>
                {!isEmployerVacancySearch && (
                    <MagritteLinkMore>
                        {!collection && <Loader />}
                        {/* TODO: перевести на магритт в https://jira.hh.ru/browse/PORTFOLIO-30300 */}
                        {collection && (
                            <CompositeSelection
                                collection={collection}
                                treeFilter={filterUniqueLeavesOnly}
                                value={selectedValues}
                                onChange={onChangeSelection}
                                title={title}
                                trl={{
                                    submit: trls[TrlKeys.save],
                                    cancel: trls[TrlKeys.cancel],
                                    searchPlaceholder: trls[TrlKeys.placeholder],
                                    notFound: trls[TrlKeys.notFound],
                                }}
                            >
                                {({ showTreeSelectorPopup }) => (
                                    <MagritteLink Element="button" onClick={showTreeSelectorPopup}>
                                        {trls[TrlKeys.chooseMore]}
                                    </MagritteLink>
                                )}
                            </CompositeSelection>
                        )}
                    </MagritteLinkMore>
                )}
            </MagritteNovaFilterWrapper>
        );
    }

    return (
        <NovaFilterWrapper title={title}>
            <FilterList>
                {filteredItems.map(({ title, id }) => {
                    const group = groups[id];
                    return (
                        <ListItem key={id}>
                            <Checkbox
                                value={id}
                                onChange={({ target }) => {
                                    onChangeFilter(target.value);
                                }}
                                checked={selectedValues.includes(id)}
                                labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                                disabled={!group}
                            >
                                <FilterTitle title={title} />
                                {group && <FilterCount count={group.count} />}
                            </Checkbox>
                        </ListItem>
                    );
                })}
            </FilterList>
            <LinkMore>
                {!collection && <Loading scale={LoadingScale.Small} />}
                {collection && (
                    <CompositeSelection
                        collection={collection}
                        treeFilter={filterUniqueLeavesOnly}
                        value={selectedValues}
                        onChange={onChangeSelection}
                        title={title}
                        trl={{
                            submit: trls[TrlKeys.save],
                            cancel: trls[TrlKeys.cancel],
                            searchPlaceholder: trls[TrlKeys.placeholder],
                            notFound: trls[TrlKeys.notFound],
                        }}
                    >
                        {({ showTreeSelectorPopup }) => (
                            <BlokoLink appearance={LinkAppearance.Pseudo} onClick={showTreeSelectorPopup}>
                                {trls[TrlKeys.chooseMore]}
                            </BlokoLink>
                        )}
                    </CompositeSelection>
                )}
            </LinkMore>
        </NovaFilterWrapper>
    );
};

export default translation(ProfessionalRolesDesktop);
