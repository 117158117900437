import { useCallback } from 'react';

import { Link, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { LanguageLevel } from 'src/models/resume/resumeCommon.types';
import sortForLanguages from 'src/utils/sortForLanguages';

const DEFAULT_LEVEL = LanguageLevel.A1;

const TrlKeys = {
    add: 'employer.resumesSearch.addLang',
    addOneMore: 'vacancyresponse.filters.language.OneMoreLanguage',
};

type Props = {
    value: string[];
    onChange: (value: string[]) => void;
    preTitleDataQa: string;
};

const AddLink: TranslatedComponent<Props> = ({ trls, value, onChange, preTitleDataQa }) => {
    const collection = useSelector((state) => sortForLanguages(state.languages.language)) || [];
    const selectedLanguages = value.map((language) => language.split('.')[0]);

    const handleClick = useCallback(
        () =>
            onChange([
                ...value,
                `${collection.find(({ code }) => !selectedLanguages.includes(code))?.code || ''}.${DEFAULT_LEVEL}`,
            ]),
        [collection, onChange, selectedLanguages, value]
    );

    if (selectedLanguages.length >= collection.length) {
        return null;
    }

    return (
        <>
            {selectedLanguages.length > 0 && <VSpacing default={8} />}
            <Link typography="label-2-regular" onClick={handleClick} data-qa={`${preTitleDataQa}-add-language`}>
                {selectedLanguages.length > 0 ? trls[TrlKeys.addOneMore] : trls[TrlKeys.add]}
            </Link>
        </>
    );
};

export default translation(AddLink);
