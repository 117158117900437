import { useState, FC } from 'react';

import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import useNovaFiltersGroups from 'src/components/NovaFilters/hooks/useNovaFiltersGroups';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import { useSelector } from 'src/hooks/useSelector';
import NovaFilters, { NovaFilterGroup, NovaFilterKey, NovaListFilters } from 'src/models/novaFilters';

import FilterList from 'src/components/NovaFilters/components/Magritte/FilterList';
import LinkMore from 'src/components/NovaFilters/components/Magritte/LinkMore';
import NovaFiltersItem from 'src/components/NovaFilters/components/Magritte/NovaFiltersItem';

const EMPTY_ORDERS = [] as string[];

export interface FilterContent {
    name: NovaFilterKey;
    sortFunc?: (a: NovaFilterGroup<string>, b: NovaFilterGroup<string>) => number;
    shouldSendCountsRequest?: boolean;
}

const NovaFilterContent: FC<FilterContent> = ({ name, sortFunc, shouldSendCountsRequest = false }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const orders = useSelector((state) => state.searchClustersOrder?.[name] || EMPTY_ORDERS);
    const { groups, selectedValues } = useSelector((state) => {
        const { groups, selectedValues } = state.searchClusters?.[name as keyof NovaListFilters];
        return { groups, selectedValues: selectedValues.map((item) => `${item}`) };
    });
    const [expanded, setExpanded] = useState(false);
    const { items, breakpoint } = useNovaFiltersGroups(groups, selectedValues.length, orders, expanded, sortFunc);
    return (
        <>
            <FilterList expanded={expanded} len={items.length}>
                {items.map((item, index) => {
                    if (index > breakpoint - 1 && !expanded) {
                        return null;
                    }
                    return (
                        <NovaFiltersItem
                            key={item.id}
                            item={item}
                            name={name}
                            onChange={() => {
                                const index = selectedValues.indexOf(item.id);
                                const newValues = [...selectedValues];
                                if (index === -1) {
                                    newValues.push(item.id);
                                } else {
                                    newValues.splice(index, 1);
                                }
                                filterUpdate(newValues, name as keyof NovaFilters);
                                shouldSendCountsRequest && sendCountsRequest();
                            }}
                            checked={selectedValues.includes(item.id)}
                        />
                    );
                })}
            </FilterList>
            {items.length > breakpoint && (
                <LinkMore
                    length={items.length - breakpoint}
                    expanded={expanded}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                />
            )}
        </>
    );
};

export default NovaFilterContent;
