import { ChangeEvent, FC } from 'react';

import ControlGroupItem from 'bloko/blocks/controlGroup/ControlGroupItem';
import Select, { Option } from 'bloko/blocks/select';

import { Language } from 'src/models/resumeLanguage';

interface LanguageSelectProps {
    code: string;
    onChange: (event: ChangeEvent<HTMLSelectElement>) => void;
    selectedLanguages: string[];
    collection: Language[];
    isVertical: boolean;
    dataQa: string;
}

const LanguageSelect: FC<LanguageSelectProps> = ({
    code,
    onChange,
    selectedLanguages,
    collection,
    isVertical,
    dataQa,
}) => (
    <ControlGroupItem vertical={isVertical}>
        <Select value={code} onChange={onChange} data-qa={dataQa}>
            {collection.map(({ id, code, title }) => (
                <Option key={id} value={code} data-qa={`${dataQa}-${code}`} disabled={selectedLanguages.includes(code)}>
                    {title}
                </Option>
            ))}
        </Select>
    </ControlGroupItem>
);

export default LanguageSelect;
