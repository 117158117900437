import Text, { TextSize } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import NovaChipsSelect, { ChipsSelectType } from 'src/components/NovaFilters/components/NovaChipsSelect';
import useNovaSortUpdate from 'src/components/NovaFilters/hooks/useNovaSortUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { SearchPeriodOption } from 'src/models/vacancySearchCriteria';

import styles from './sorts.less';

const TrlKeys = {
    searchPeriodTitle: 'vacancySearch.searchPeriod',
    [SearchPeriodOption.AllTime]: 'vacancySearch.searchperiod.all',
    [SearchPeriodOption.Month]: 'vacancySearchResults.searchPeriod.30',
    [SearchPeriodOption.Week]: 'vacancySearchResults.searchPeriod.7',
    [SearchPeriodOption.ThreeDays]: 'vacancySearchResults.searchPeriod.3',
    [SearchPeriodOption.Day]: 'vacancySearchResults.searchPeriod.1',
};

const SearchPeriod: TranslatedComponent = ({ trls }) => {
    const options = useSelector((state) => state.vacancySearchDictionaries.searchPeriod);
    const value = useSelector((state) => state.novaSorts.searchPeriod);
    const handleChangeSort = useNovaSortUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();

    if (!options) {
        return null;
    }

    return (
        <div>
            <Text Element="span" size={TextSize.Large} strong>
                {trls[TrlKeys.searchPeriodTitle]}
            </Text>
            <VSpacing base={3} />
            <NovaChipsSelect
                name={'search_period'}
                selected={[`${value}`]}
                data-qa="serp-settings__search-period-menu"
                options={options.map((option) => ({
                    id: option,
                    title: <p className={styles.novaSort}>{trls[TrlKeys[option]]}</p>,
                }))}
                selectType={ChipsSelectType.Single}
                onChange={(values) => {
                    handleChangeSort('searchPeriod', values[0]);
                    sendCountsRequest();
                }}
            />
            <VSpacing base={6} />
        </div>
    );
};

export default translation(SearchPeriod);
