import { useState, useRef } from 'react';

import { Checkbox as MagritteCheckbox } from '@hh.ru/magritte-ui';
import Checkbox from 'bloko/blocks/checkbox';
import { FormItem } from 'bloko/blocks/form';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CurrencySelector from 'src/components/NovaFilters/components/CurrencySelector';
import FilterCount from 'src/components/NovaFilters/components/FilterCount';
import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';
import ListItem from 'src/components/NovaFilters/components/ListItem';
import MagritteCurrencySelector from 'src/components/NovaFilters/components/Magritte/CurrencySelector';
import MagritteNovaControl from 'src/components/NovaFilters/components/Magritte/NovaControl';
import MagritteNovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import MagritteNovaSwitchItem from 'src/components/NovaFilters/components/Magritte/NovaSwitchItem';
import MagritteCompensationList from 'src/components/NovaFilters/components/Magritte/vacancies/Compensation/CompensationList';
import MagritteCompensationMobile from 'src/components/NovaFilters/components/Magritte/vacancies/Compensation/CompensationMobile';
import NovaControl from 'src/components/NovaFilters/components/NovaControl';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NovaFilterKey } from 'src/models/novaFilters';
import { CriteriaKey } from 'src/models/search/searchCriteria.types';

import CompensationList from 'src/components/NovaFilters/vacancies/Compensation/CompensationList';
import CompensationMobile from 'src/components/NovaFilters/vacancies/Compensation/CompensationMobile';
import CustomCompensation from 'src/components/NovaFilters/vacancies/Compensation/CustomCompensation';

const TrlKeys = {
    title: 'searchvacancy.clusters.income',
    withSalaryTitle: 'searchvacancy.clusters.notWithoutIncome.active',
};

/**
 * Блок фильтра по доходу на поиске вакансий.
 */
const Compensation: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte, trls }) => {
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const groups = useSelector((state) => state.searchClusters[NovaFilterKey.Compensation]?.groups) || {};
    const selectedValues = useSelector(
        (state) => state.searchClusters[NovaFilterKey.Compensation]?.selectedValues || {}
    );
    const initialValueFrom = selectedValues?.salary?.toString() || '';
    const withSalaryGroup = groups?.only_with_salary; // eslint-disable-line camelcase

    const lastUpdatedValue = useRef(initialValueFrom);
    const [currentValueFrom, setCurrentValueFrom] = useState(initialValueFrom);
    const [withSalary, setWithSalary] = useState(selectedValues?.onlyWithSalary || false);
    const [isCustomCompensation, setIsCustomCompensation] = useState(
        currentValueFrom !== '' && !Object.keys(groups).includes(currentValueFrom)
    );

    const updateFilters = (value?: string, withoutFormSending?: boolean) => {
        const newValue = value || currentValueFrom;
        if (lastUpdatedValue.current !== newValue) {
            filterUpdate(
                { onlyWithSalary: withSalary, salary: newValue },
                NovaFilterKey.Compensation,
                withoutFormSending
            );
            if (!withoutFormSending) {
                lastUpdatedValue.current = newValue;
            }
        }
    };

    const onChangeListValue = (salary: string, onlyWithSalary: boolean, withoutFormSending?: boolean) => {
        setCurrentValueFrom(salary);
        setWithSalary(onlyWithSalary);
        filterUpdate({ onlyWithSalary, salary }, NovaFilterKey.Compensation, withoutFormSending);
        setIsCustomCompensation(false);
    };

    const onChangeCompensationMobile = (value: string) => {
        if (!value) {
            onChangeListValue('', false);
            sendCountsRequest();
            return;
        }
        updateFilters(value);
        setCurrentValueFrom(value);
        setIsCustomCompensation(true);
        sendCountsRequest();
    };

    const onChangeWithSalary = () => {
        const newValue = !withSalary;
        setWithSalary(newValue);
        filterUpdate({ onlyWithSalary: newValue, salary: currentValueFrom }, NovaFilterKey.Compensation);
        sendCountsRequest();
    };

    if (isMagritte) {
        return (
            <MagritteNovaControl
                mobileView={
                    <>
                        <MagritteCompensationMobile
                            amount={currentValueFrom}
                            title={trls[TrlKeys.title]}
                            onChange={onChangeCompensationMobile}
                        />
                        <MagritteNovaSwitchItem
                            title={trls[TrlKeys.withSalaryTitle]}
                            checked={withSalary}
                            onClick={onChangeWithSalary}
                            dataQa="serp__novafilter-only_with_salary"
                        />
                    </>
                }
            >
                <MagritteNovaFilterWrapper
                    title={trls[TrlKeys.title]}
                    titleSideElement={
                        <MagritteCurrencySelector
                            parentFilterKey={NovaFilterKey.Compensation}
                            onCurrencySelectorChange={() => {
                                onChangeListValue('', withSalary, true);
                                sendCountsRequest();
                            }}
                        />
                    }
                >
                    <MagritteCompensationList
                        name={NovaFilterKey.Salary}
                        groups={groups}
                        currentValueFrom={currentValueFrom}
                        isCustomCompensation={isCustomCompensation}
                        onChangeListValue={onChangeListValue}
                    />
                    <CustomCompensation
                        isMagritte
                        isInputEnabled={isCustomCompensation}
                        currentValueFrom={currentValueFrom}
                        setInputEnabled={setIsCustomCompensation}
                        setCurrentValueFrom={setCurrentValueFrom}
                        updateFilters={updateFilters}
                    />
                    <MagritteNovaFilterItemWrapper
                        left={
                            <MagritteCheckbox
                                name={CriteriaKey.OnlyWithSalary}
                                onChange={onChangeWithSalary}
                                checked={withSalary}
                                dataQaCheckbox="serp__novafilter-only_with_salary"
                            />
                        }
                        title={trls[TrlKeys.withSalaryTitle]}
                        count={withSalaryGroup?.count}
                    />
                </MagritteNovaFilterWrapper>
            </MagritteNovaControl>
        );
    }

    return (
        <NovaControl
            mobileView={
                <>
                    <CompensationMobile
                        amount={currentValueFrom}
                        title={trls[TrlKeys.title]}
                        onChange={onChangeCompensationMobile}
                    />
                    <Checkbox
                        name={CriteriaKey.OnlyWithSalary}
                        onChange={onChangeWithSalary}
                        checked={withSalary}
                        data-qa="serp__novafilter-only_with_salary"
                        labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                    >
                        <FilterTitle title={trls[TrlKeys.withSalaryTitle]} />
                    </Checkbox>
                    <VSpacing base={6} />
                </>
            }
        >
            <NovaFilterWrapper
                title={trls[TrlKeys.title]}
                titleSideElement={
                    <CurrencySelector
                        parentFilterKey={NovaFilterKey.Compensation}
                        onCurrencySelectorChange={() => {
                            onChangeListValue('', withSalary, true);
                            sendCountsRequest();
                        }}
                    />
                }
            >
                <CompensationList
                    name={NovaFilterKey.Salary}
                    groups={groups}
                    currentValueFrom={currentValueFrom}
                    isCustomCompensation={isCustomCompensation}
                    onChangeListValue={onChangeListValue}
                />
                <CustomCompensation
                    isInputEnabled={isCustomCompensation}
                    currentValueFrom={currentValueFrom}
                    setInputEnabled={setIsCustomCompensation}
                    setCurrentValueFrom={setCurrentValueFrom}
                    updateFilters={updateFilters}
                />
                <FormItem>
                    <ListItem>
                        <Checkbox
                            name={CriteriaKey.OnlyWithSalary}
                            onChange={onChangeWithSalary}
                            checked={withSalary}
                            data-qa="serp__novafilter-only_with_salary"
                            labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                        >
                            <FilterTitle title={trls[TrlKeys.withSalaryTitle]} />
                            {withSalaryGroup?.count && <FilterCount count={withSalaryGroup.count} />}
                        </Checkbox>
                    </ListItem>
                </FormItem>
            </NovaFilterWrapper>
        </NovaControl>
    );
};

export default translation(Compensation);
