import { useCallback, useState } from 'react';

import debounce from 'bloko/common/debounce';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { AdditionalDefault } from 'bloko/common/tree/types';

import ChildrenMobileFilter, { MultiAction } from 'src/components/NovaFilters/components/NovaTree/ChildrenMobileFilter';
import ParentMobileFilter from 'src/components/NovaFilters/components/NovaTree/ParentMobileFilter';
import useNovaFilterUpdate from 'src/components/NovaFilters/hooks/useNovaFilterUpdate';
import { useDebouncedCountsRequest } from 'src/components/NovaFilters/hooks/useSendFilterForm';
import translation from 'src/components/translation';
import { NovaFilterKey } from 'src/models/novaFilters';

import MagritteNovaBottomSheet from 'src/components/NovaFilters/components/Magritte/NovaBottomSheet';

type FiltersMobileTreeSelectorProps = {
    name: typeof NovaFilterKey.FilterExpIndustry | typeof NovaFilterKey.ProfessionalRole;
    initialValues: string[];
    collection: TreeCollection<AdditionalDefault>;
    showModal: boolean;
    setShowModal: (value: boolean) => void;
};

const TrlKeys = {
    searchParent: 'novaFilters.professionalRoles.search.parent',
    searchChild: 'novaFilters.professionalRoles.search.child',
};

const FiltersMobileTreeSelector: TranslatedComponent<FiltersMobileTreeSelectorProps> = ({
    name,
    initialValues,
    collection,
    showModal,
    setShowModal,
    trls,
}) => {
    // store's data
    const filterUpdate = useNovaFilterUpdate();
    const sendCountsRequest = useDebouncedCountsRequest();
    const [selectedValues, setSelectedValues] = useState<string[]>(initialValues);

    // search text and input
    const [searchQuery, setQuery] = useState<string>('');
    const [searchText, setSearchText] = useState<string>('');
    const [selectedParent, setSelectedParent] = useState<string>('');

    // update text (debounce for render children)
    const searchRolesDebounced = useCallback(
        debounce((text: string) => {
            setSearchText(text);
        }, 400),
        []
    );

    // update search input
    const onChangeSearchQuery = useCallback(
        (value: string) => {
            searchRolesDebounced(value);
            setQuery(value);
        },
        [searchRolesDebounced]
    );

    // click on parent and child
    const onChangeParent = useCallback((id: string) => {
        setSelectedParent(id);
    }, []);

    // all checkbox
    const onMultiChange = useCallback(
        (action: MultiAction, changedValues: string[]) => {
            const newValues =
                action === MultiAction.Add
                    ? [...selectedValues, ...changedValues]
                    : selectedValues.filter((value) => !changedValues.includes(value));
            setSelectedValues(newValues);
            filterUpdate(newValues, name);
        },
        [filterUpdate, name, selectedValues]
    );

    const onChangeChild = useCallback(
        (id: string) => {
            const index = selectedValues.indexOf(id);
            const newSelectedValues = [...selectedValues];
            if (index === -1) {
                newSelectedValues.push(id);
            } else {
                newSelectedValues.splice(index, 1);
            }
            setSelectedValues(newSelectedValues);
            filterUpdate(newSelectedValues, name);
        },
        [filterUpdate, name, selectedValues]
    );

    const handleClose = useCallback(() => {
        setShowModal(false);
        setQuery('');
        setSearchText('');
        onChangeParent('');
        sendCountsRequest();
    }, [onChangeParent, sendCountsRequest, setShowModal]);

    const titleModal = trls[selectedParent ? TrlKeys.searchChild : TrlKeys.searchParent];

    return (
        <MagritteNovaBottomSheet
            showModal={showModal}
            title={titleModal}
            searchQuery={searchQuery}
            onChangeSearchQuery={onChangeSearchQuery}
            showBack={!!selectedParent}
            onBack={() => {
                if (selectedParent) {
                    onChangeParent('');
                    return;
                }
                handleClose();
            }}
            onClose={handleClose}
        >
            {!selectedParent && showModal && (
                <ParentMobileFilter
                    collection={collection}
                    searchText={searchText}
                    selectedValues={selectedValues}
                    onChange={onChangeParent}
                    isMagritte
                />
            )}
            {selectedParent && (
                <ChildrenMobileFilter
                    collection={collection}
                    searchText={searchText}
                    parent={selectedParent}
                    selectedValues={selectedValues}
                    onChange={onChangeChild}
                    name={name}
                    onMultiChange={onMultiChange}
                    isMagritte
                />
            )}
        </MagritteNovaBottomSheet>
    );
};

export default translation(FiltersMobileTreeSelector);
