import { useEffect, useMemo, useState } from 'react';

import { Checkbox as MagritteCheckbox, VSpacing as MagritteVSpacing } from '@hh.ru/magritte-ui';
import Checkbox from 'bloko/blocks/checkbox';
import VSpacing from 'bloko/blocks/vSpacing';
import FuzzySearch from 'bloko/common/fuzzySearch';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import TreeCollection from 'bloko/common/tree/treeCollection';
import { AdditionalDefault, CoreField, TreeModel } from 'bloko/common/tree/types';

import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';
import ListItem from 'src/components/NovaFilters/components/ListItem';
import MagritteNovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaFiltersItem from 'src/components/NovaFilters/components/Magritte/NovaFiltersItem';
import NovaFiltersItem from 'src/components/NovaFilters/components/NovaFiltersItem';
import translation from 'src/components/translation';
import { NovaFilterKey } from 'src/models/novaFilters';

const TrlKeys = {
    all: 'novaFilters.childrenFilter.all',
};

export enum MultiAction {
    Add = 'add',
    Delete = 'delete',
}

interface ChildrenFilterPros {
    collection: TreeCollection<AdditionalDefault>;
    searchText: string;
    parent: string;
    selectedValues: string[];
    onChange: (id: string) => void;
    onMultiChange: (action: MultiAction, values: string[]) => void;
    name: NovaFilterKey;
    isMagritte?: boolean;
}

const ChildrenMobileFilter: TranslatedComponent<ChildrenFilterPros> = ({
    trls,
    collection,
    searchText,
    parent,
    selectedValues,
    onChange,
    onMultiChange,
    name,
    isMagritte,
}) => {
    const [categories, setCategories] = useState<TreeModel<AdditionalDefault>[]>([]);
    const selectedAll = useMemo(
        () => categories.every((category) => selectedValues.includes(category[CoreField.Id])),
        [categories, selectedValues]
    );

    useEffect(() => {
        const children = collection.getChildren(parent);
        if (!searchText) {
            setCategories(children);
            return;
        }
        setCategories(children.filter((category) => FuzzySearch.match(searchText, category[CoreField.Text])));
    }, [collection, parent, searchText]);

    if (isMagritte) {
        return (
            <div>
                {!searchText && (
                    <MagritteNovaFilterItemWrapper
                        left={
                            <MagritteCheckbox
                                onChange={() => {
                                    onMultiChange(
                                        selectedAll ? MultiAction.Delete : MultiAction.Add,
                                        collection.getChildrenIds(parent)
                                    );
                                }}
                                checked={selectedAll}
                            />
                        }
                        title={trls[TrlKeys.all]}
                    />
                )}
                <MagritteVSpacing default={12} />
                {categories.map((category) => {
                    const id = category[CoreField.Id];
                    return (
                        <MagritteNovaFiltersItem
                            key={id}
                            name={name}
                            item={{ title: category[CoreField.Text], id, count: 0, order: 0 }}
                            onChange={onChange}
                            checked={selectedValues.includes(id)}
                        />
                    );
                })}
            </div>
        );
    }

    return (
        <div>
            {!searchText && (
                <ListItem>
                    <Checkbox
                        onChange={() => {
                            onMultiChange(
                                selectedAll ? MultiAction.Delete : MultiAction.Add,
                                collection.getChildrenIds(parent)
                            );
                        }}
                        checked={selectedAll}
                    >
                        <FilterTitle title={trls[TrlKeys.all]} />
                    </Checkbox>
                </ListItem>
            )}
            <VSpacing base={2} />
            {categories.map((category) => {
                const id = category[CoreField.Id];
                return (
                    <NovaFiltersItem
                        key={id}
                        name={name}
                        item={{ title: category[CoreField.Text], id, count: 0, order: 0 }}
                        onChange={onChange}
                        checked={selectedValues.includes(id)}
                        hideCount
                    />
                );
            })}
        </div>
    );
};

export default translation(ChildrenMobileFilter);
