import { useState, Fragment, FC } from 'react';

import { Checkbox as MagritteCheckbox } from '@hh.ru/magritte-ui';
import Checkbox from 'bloko/blocks/checkbox';

import FilterCount from 'src/components/NovaFilters/components/FilterCount';
import FilterList from 'src/components/NovaFilters/components/FilterList';
import FilterTitle from 'src/components/NovaFilters/components/FilterTitle';
import LinkMore from 'src/components/NovaFilters/components/LinkMore';
import ListItem from 'src/components/NovaFilters/components/ListItem';
import MagritteFilterList from 'src/components/NovaFilters/components/Magritte/FilterList';
import MagritteLinkMore from 'src/components/NovaFilters/components/Magritte/LinkMore';
import MagritteListItem from 'src/components/NovaFilters/components/Magritte/ListItem';
import MagritteNovaFilterItemWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterItemWrapper';
import MagritteNovaFilterWrapper from 'src/components/NovaFilters/components/Magritte/NovaFilterWrapper';
import NovaFilterWrapper from 'src/components/NovaFilters/components/NovaFilterWrapper';
import useChangeChildTreeValue from 'src/components/NovaFilters/hooks/useChangeChildTreeValue';
import useGroupValueByParent from 'src/components/NovaFilters/hooks/useGroupValueByParent';
import { HIDE_FILTERS_AFTER } from 'src/components/NovaFilters/hooks/useNovaFiltersGroups';
import NovaFilters, { NovaFilterKey } from 'src/models/novaFilters';

interface Props {
    title: string;
    parentField: 'industryId';
    parentFilter: NovaFilters[typeof NovaFilterKey.Industry];
    childrenFilter: NovaFilters[typeof NovaFilterKey.SubIndustry];
    setCheckedValuesWithOnChange: (newValues: string[]) => void;
    selectedValues: string[];
    childrenOrder: string[];
    isMagritte?: boolean;
}

const NovaFilterChildTree: FC<Props> = ({
    title,
    setCheckedValuesWithOnChange,
    selectedValues,
    parentFilter,
    childrenFilter,
    parentField,
    childrenOrder = [],
    isMagritte,
}) => {
    const childrenLength = childrenFilter ? Object.values(childrenFilter.groups).length : 0;
    const breakpoint = childrenLength - HIDE_FILTERS_AFTER === 1 ? childrenLength : HIDE_FILTERS_AFTER;
    const [expanded, setExpanded] = useState(false);
    const childrenMap = useGroupValueByParent(childrenFilter?.groups || {}, parentField, childrenOrder);

    const onChangeChildren = useChangeChildTreeValue(selectedValues, setCheckedValuesWithOnChange);
    if (!childrenFilter || childrenLength === 0) {
        return null;
    }
    let commonIndex = 0;

    if (isMagritte) {
        return (
            <MagritteNovaFilterWrapper title={title}>
                <MagritteFilterList expanded={expanded} len={childrenLength}>
                    {Object.keys(childrenMap).map((parentId) => {
                        const parent = parentFilter.groups[parentId];
                        if (commonIndex > breakpoint - 1 && !expanded) {
                            return null;
                        }
                        return (
                            <Fragment key={parentId}>
                                {parent && <MagritteListItem header>{parent.title}</MagritteListItem>}
                                {Object.values(childrenMap[parentId]).map(({ id, title, count }) => {
                                    if (commonIndex > breakpoint - 1 && !expanded) {
                                        return null;
                                    }
                                    commonIndex += 1;
                                    return (
                                        <MagritteNovaFilterItemWrapper
                                            key={id}
                                            left={
                                                <MagritteCheckbox
                                                    value={id}
                                                    onChange={({ target }) => {
                                                        onChangeChildren(target.value);
                                                    }}
                                                    checked={selectedValues.includes(id)}
                                                    dataQaCheckbox="serp__novafilter-item-text"
                                                />
                                            }
                                            title={title}
                                            count={count}
                                        />
                                    );
                                })}
                            </Fragment>
                        );
                    })}
                </MagritteFilterList>
                {childrenLength > breakpoint && (
                    <MagritteLinkMore
                        length={childrenLength - breakpoint}
                        expanded={expanded}
                        onClick={() => {
                            setExpanded(!expanded);
                        }}
                    />
                )}
            </MagritteNovaFilterWrapper>
        );
    }

    return (
        <NovaFilterWrapper title={title}>
            <FilterList expanded={expanded} len={childrenLength}>
                {Object.keys(childrenMap).map((parentId) => {
                    const parent = parentFilter.groups[parentId];
                    if (commonIndex > breakpoint - 1 && !expanded) {
                        return null;
                    }
                    return (
                        <Fragment key={parentId}>
                            {parent && <ListItem header>{parent.title}</ListItem>}
                            {Object.values(childrenMap[parentId]).map(({ id, title, count }) => {
                                if (commonIndex > breakpoint - 1 && !expanded) {
                                    return null;
                                }
                                commonIndex += 1;
                                return (
                                    <ListItem key={id}>
                                        <Checkbox
                                            value={id}
                                            onChange={({ target }) => {
                                                onChangeChildren(target.value);
                                            }}
                                            checked={selectedValues.includes(id)}
                                            labelProps={{ 'data-qa': 'serp__novafilter-item-text' }}
                                        >
                                            <FilterTitle title={title} />
                                            <FilterCount count={count} />
                                        </Checkbox>
                                    </ListItem>
                                );
                            })}
                        </Fragment>
                    );
                })}
            </FilterList>
            {childrenLength > breakpoint && (
                <LinkMore
                    length={childrenLength - breakpoint}
                    expanded={expanded}
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                />
            )}
        </NovaFilterWrapper>
    );
};

export default NovaFilterChildTree;
